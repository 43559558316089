#modal-change-region .modal__bar .bar {
  height: 8px; }
  #modal-change-region .modal__bar .bar__pink {
    background-position-x: -700px; }

@media (min-width: 576px) {
  #modal-change-region .modal__container {
    width: 400px; } }

#modal-change-region .modal__label {
  cursor: pointer; }

#modal-change-region .modal__list-item {
  width: fit-content; }
